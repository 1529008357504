import { ProductAddOnDto, ProductTrackingDto } from '~/features/commerce/product-details/types';
import { OrderItem } from '~/features/commerce/shopping-cart/types';

/**
 * Retrieves only the tracking info from the provided add ons
 * @param addOns
 */
export function getProductTrackingItemsFromAddOns(addOns: ProductAddOnDto[]) {
	return addOns.map(({ productTracking }) => productTracking).filter((pt) => pt) as ProductTrackingDto[];
}

/**
 * Retrieves the add ons from the order item
 * @param orderItem
 */
export function getAddOnsFromOrderItem(orderItem: OrderItem) {
	const selectedAddons = orderItem.addOns?.filter(({ selected }) => selected) ?? [];
	const quantity = Math.ceil(orderItem.quantity * orderItem.pricingLine.quantity);

	return selectedAddons.map((addOn) => {
		addOn.quantity = quantity;
		if (addOn.productTracking) {
			addOn.productTracking.quantity = quantity;
		}

		return addOn;
	});
}
