import { ProductTrackingDto } from 'features/commerce/product-details/types';
import { dispatchRocEvent } from 'shared/hooks/useRocEventHandler';
import { TrackingListDataset } from '../types';

/**
 * Object that holds state regarding this group of items that are being tracked/observed, for the view-list GA4 event
 */
interface ViewListInfoGroup {
	trackingItemListId?: string;
	trackingItemListName?: string;
	/**
	 * Whether this group of events has been tracked already
	 */
	eventGroupTracked: boolean;
	/**
	 * Data on all the items that are in this group that are being observed
	 */
	items: ProductTrackingDto[];
}

/**
 * HTMLElement with extra data added to it for list-view tracking
 */
interface TrackingInfoElement extends HTMLElement {
	trackingItemListId?: string;
	trackingItemListName?: string;
	trackingItemListGroup?: ViewListInfoGroup;
	/**
	 * Whether this element is already being observed or not, used to help prevent multiple observers for the same event
	 */
	isBeingObservedForListView?: boolean;
}

/**
 * A ProductTrackingDto that also contains information needed for the GA4 view-list event
 */
interface TrackingListProductItem extends ProductTrackingDto {
	listId?: string;
	listName?: string;
}

/**
 * Set up observers on SSR-pages to fire the view-list GA4 event, when any item in the list is viewed.
 * Can handle multiple groups of items with different tracking list ids and names
 */
export function setupViewListObservers() {
	// Don't want to do this for items cloned by tiny-slider
	const elementsWithTrackingEnabled = document.querySelectorAll(
		':not(.tns-slide-cloned) > [data-roc-tracking-list-enabled]',
	);

	// Extract tracking info objects from the server-side data
	const trackingInfo = Array.from(elementsWithTrackingEnabled).map((e) => {
		// dataset needs to exist, as that's how we found the elements in the first place
		const dataset = (e as HTMLElement).dataset! as TrackingListDataset;

		const trackingInfoJson = dataset.rocTrackingProductInfo;
		const trackingInfo = JSON.parse(trackingInfoJson ?? '') as ProductTrackingDto;

		// store tracking info for later
		const trackableElem = e as TrackingInfoElement;
		trackableElem.trackingItemListId = dataset.rocTrackingListId;
		trackableElem.trackingItemListName = dataset.rocTrackingListName;

		// return tracking info for further processing
		return {
			...trackingInfo,
			listId: dataset.rocTrackingListId,
			listName: dataset.rocTrackingListName,
		} as TrackingListProductItem;
	});

	const trackingGroups = getTrackingGroups(trackingInfo);

	// Create a new observer to handle tracking when a tracking group is viewed
	const visibilityObserver = new IntersectionObserver((entries) => {
		for (const intersectionEntry of entries) {
			const targetTrackingInfo = (intersectionEntry.target as TrackingInfoElement).trackingItemListGroup;

			// only run for tracking groups that are visible and haven't already triggered this observer
			if (
				targetTrackingInfo &&
				!targetTrackingInfo?.eventGroupTracked &&
				intersectionEntry.isIntersecting === true
			) {
				// Make sure this only runs once on the page
				targetTrackingInfo.eventGroupTracked = true;

				// dispatch the roc event that the user has viewed the list
				dispatchRocEvent('list-viewed', {
					listId: targetTrackingInfo.trackingItemListId,
					listName: targetTrackingInfo.trackingItemListName,
					items: targetTrackingInfo.items,
				});
			}
		}
	});

	// Add an intersection observer for each element that we want to track,
	// and assign the same object for tracking info to all elements of the same group,
	// so they don't send the "view list" event more than once per group
	elementsWithTrackingEnabled.forEach((elem) => {
		const enhancedElement = elem as TrackingInfoElement;
		if (enhancedElement.isBeingObservedForListView) {
			// don't want to add multiple observers of the same type to the same element, if this is run multiple times
			return;
		}

		// Find the tracking group this element belongs to, and assign it
		const relevantTrackingGroup = trackingGroups.find(
			(tg) =>
				tg.trackingItemListId === enhancedElement.trackingItemListId &&
				tg.trackingItemListName == enhancedElement.trackingItemListName,
		);
		enhancedElement.trackingItemListGroup = relevantTrackingGroup;

		// Help prevent accidentally adding multiple observers of the same type to this element
		enhancedElement.isBeingObservedForListView = true;
		visibilityObserver.observe(elem);
	});
}

/**
 * Group `TrackingListProductItem`s by their ListId and ListName.
 */
export function getTrackingGroups(productTrackingInfo: TrackingListProductItem[]) {
	const generatedEvents: ViewListInfoGroup[] = [];

	// Transform a {trackingItemListId, trackingItemListName, productInfo}[]
	// into {uniqueTrackingItemListId, uniqueTrackingItemListName, productInfo[]}[]
	for (const product of productTrackingInfo) {
		const foundExistingEvent = generatedEvents.find(
			(ge) => ge.trackingItemListId === product.listId && ge.trackingItemListName === product.listName,
		);

		if (foundExistingEvent) {
			foundExistingEvent.items.push(product);
		} else {
			const newEvent: ViewListInfoGroup = {
				eventGroupTracked: false,
				trackingItemListId: product.listId,
				trackingItemListName: product.listName,
				items: [product],
			};
			generatedEvents.push(newEvent);
		}
	}

	return generatedEvents;
}
