import { useEffect } from 'react';
import 'shared/polyfills/customEvent';
import { RocEventType, ExtractEventParameters, RocEvent } from 'shared/events';
import addRocEventHandler from 'shared/util/events/rocEventUtils';

/**
 * Helper function for dispatching ROC events.
 *
 * @param eventType The event type for the dispatcher
 */
export function dispatchRocEvent<T extends RocEventType>(eventType: T, data: ExtractEventParameters<RocEvent, T>) {
	const event = new CustomEvent(eventType, {
		detail: data,
	});
	document.dispatchEvent(event);
}

/**
 * Reusable hook for handling ROC events
 *
 * @param eventType The type of event to handle
 * @param eventHandler The event handler
 * @param deps List of dependencies
 */
export default function useRocEventHandler<T extends RocEventType>(
	eventType: T,
	eventHandler: (data: ExtractEventParameters<RocEvent, T>) => void,
	deps?: any[],
) {
	useEffect(() => {
		const removeHandlerCallback = addRocEventHandler(eventType, eventHandler);

		return () => {
			removeHandlerCallback();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}
