/**
 * Defines Checkout Steps, including review steps. Doesn't have an explicit definition
 * for per-recipient steps, that should be handled separately.
 */
export enum CheckoutStep {
	Loading = 0,
	LoginOrGuest = 1,
	ShippingAddress = 2,
	ShippingMethod = 3,
	ShippingReview = 4,
	Payment = 5,
	Review = 6,
	Confirmation = 7,
}
