/* eslint-disable camelcase */

import { ProductTrackingDto } from 'features/commerce/product-details/types';
import { ServerData } from 'services/ServerData';
import { GoogleAnalyticsOrderItem } from './types';
import { Order, OrderItem } from '~/features/commerce/shopping-cart/types';
import { getAddOnsFromOrderItem, getProductTrackingItemsFromAddOns } from '~/features/commerce/tracking/helpers';
import { isEmpty } from 'lodash-es';

interface ConvertToGA4ItemProps {
	productTracking: ProductTrackingDto;
	index: number;
}

/**
 * Converts a product tracking object into the expected format for Google Analytics
 */
export function convertProductTrackingDtoToGoogleAnalyticsItem(props: ConvertToGA4ItemProps) {
	const { productTracking, index } = props;

	if (!productTracking) {
		throw new Error('Product tracking information was not provided! Cannot create google analytics item.');
	}

	// Get the base tracking object. If the product has a group, use the groups data
	const { productName, productId, brand } = productTracking.productGroup || productTracking;

	// Get pricing data
	const price = productTracking.rawDefaultBasePrice;
	const discount = calculateProductTrackingDiscount(productTracking);

	// Get the category tree
	const defaultCategoryTree = getDefaultCategoryTree(productTracking);

	// GA4 doesn't support decimal quantities so we round up for this
	const quantity = Math.ceil(productTracking.quantity ?? 1);

	// Use the currency passed in from the server, if it's set. Otherwise use the currency for this site
	const currency = productTracking.currency ?? ServerData.CurrentCurrency;

	// Coupons is a single field, so we need to concat them and split by comma
	const coupons = productTracking.couponCodes?.join(',');

	// Item variant is a single field, so we need to concat them and split by comma
	const itemVariant = productTracking.selections?.map((selection) => selection.label).join(',');

	const googleCategories = convertTreeToCategories(defaultCategoryTree);

	const ga4Item: GoogleAnalyticsOrderItem = {
		item_id: productId,
		item_name: productName,
		affiliation: undefined,
		coupon: coupons,
		currency: currency,
		discount: discount,
		index,
		item_brand: brand?.name,
		item_variant: itemVariant,
		location_id: undefined,
		price,
		quantity,
		...googleCategories,
	};

	return ga4Item;
}

/**
 * Calculates the discount for a product. Discounts calculated by
 * subtracting base price of the product from it's sale price, if any.
 */
export function calculateProductTrackingDiscount(productTracking: ProductTrackingDto) {
	if (!productTracking.rawDefaultBasePrice || !productTracking.rawDefaultSalePrice) {
		return 0;
	}

	return productTracking.rawDefaultBasePrice - productTracking.rawDefaultSalePrice;
}

/**
 * Gets the default category tree for a product, should it exits.
 */
export function getDefaultCategoryTree(productTracking: ProductTrackingDto, recursionLimit: number = 2) {
	if (productTracking.productGroup && recursionLimit > 0) {
		return getDefaultCategoryTree(productTracking.productGroup, recursionLimit - 1);
	}

	return productTracking.categories?.find((productCategory) => productCategory.isDefault)?.category.treeName;
}

/**
 * Helper function that converts a category tree string into a object where each category corresponds to a property,
 * e.g. `{ item_category: 'Mens', item_category2: 'Clothing' }`
 */
export function convertTreeToCategories(categoryTree?: string) {
	if (!categoryTree) {
		return {};
	}

	const categories = categoryTree.split(/[>/]+/).map((category) => category.trim());

	return categories.reduce((categories, category, index) => {
		let key = 'item_category';

		if (index > 0) {
			key += index + 1;
		}

		return { ...categories, [key]: category };
	}, {});
}

/**
 * Helper function that gets the total monetary value for the Google Analytics event
 */
export function getValueForGoogleAnalyticsEvent(items: ProductTrackingDto[]) {
	return items.reduce((total, item) => {
		let result = total;
		if (item.value) {
			result += item.value;
		}

		if (item.trackedAddOns) {
			result += getValueForGoogleAnalyticsEvent(item.trackedAddOns);
		}

		return result;
	}, 0);
}

/**
 * Converts product tracking items to Google Analytics compatible items
 */
export function convertProductTrackingItemsToGoogleAnalyticsItems(items: ProductTrackingDto[], startIndex?: number) {
	const result = [] as GoogleAnalyticsOrderItem[];

	let index = startIndex ?? 0;
	items.forEach((item) => {
		result.push(convertProductTrackingDtoToGoogleAnalyticsItem({ productTracking: item, index }));

		const addOnItems =
			item.trackedAddOns?.map((addOnItem) => {
				index += 1;
				return convertProductTrackingDtoToGoogleAnalyticsItem({ productTracking: addOnItem, index });
			}) ?? [];

		result.push(...addOnItems);
		index += 1;
	});

	return result;
}

/**
 * Helper function for converting order model to the list of items in format expected by Google Analytics
 * @param order
 */
export function convertOrderToGoogleAnalyticItems(order: Order): Array<GoogleAnalyticsOrderItem> {
	const addOns = [] as ProductTrackingDto[];

	let index = 0;
	const items = order.recipients.reduce(
		(items, recipient) => [
			...items,
			...recipient.items.map((item) => {
				const productTracking = convertOrderItemToProductTrackingDto(order, item);

				addOns.push(...(productTracking.trackedAddOns ?? []));

				const result = convertProductTrackingDtoToGoogleAnalyticsItem({ productTracking, index });

				index += 1;
				return result;
			}),
		],
		[],
	);

	if (!isEmpty(addOns)) {
		const addOnItems = convertProductTrackingItemsToGoogleAnalyticsItems(addOns, index);
		items.push(...addOnItems);
	}

	return items;
}

/**
 * Converts order item to product tracking DTO
 * @param order the order that the order item is part of
 * @param item the order item
 */
function convertOrderItemToProductTrackingDto(order: Order, item: OrderItem) {
	const price = item.unitPrice?.basePrice.rawValue ?? 0.0;
	const salePrice = item.unitPrice?.salePrice ? price - item.unitPrice.salePrice.rawValue : 0.0;

	const orderItemAddOns = getAddOnsFromOrderItem(item);
	const addOnsTrackingItems = getProductTrackingItemsFromAddOns(orderItemAddOns);

	const trackingItem: ProductTrackingDto = {
		productId: item.product.id,
		productName: item.productGroupName ?? item.name,
		quantity: item.quantity,
		currency: order.currency,
		couponCodes: item.couponCodes,
		brand: item.product.brand,
		selections: item.selections,
		sku: item.sku,
		categories: item.product.categories,
		rawDefaultBasePrice: price,
		rawDefaultSalePrice: salePrice,
		trackedAddOns: addOnsTrackingItems,
	};

	return trackingItem;
}
