/* eslint-disable camelcase */
import { CheckoutStep } from 'features/commerce/checkout/types/checkout-steps';
import gtag from 'features/tracking/gtag';
import addRocEventHandler from 'shared/util/events/rocEventUtils';
import {
	convertProductTrackingDtoToGoogleAnalyticsItem,
	convertProductTrackingItemsToGoogleAnalyticsItems,
	convertOrderToGoogleAnalyticItems,
	getValueForGoogleAnalyticsEvent,
} from './helpers';
import { GoogleAnalyticsOrderItem } from './types';

interface GoogleAnalyticsBaseEventArgs {
	currency?: string;
	value?: number;
	items: GoogleAnalyticsOrderItem[];
}

interface CheckoutEventArgs extends GoogleAnalyticsBaseEventArgs {
	coupon?: string;
}

interface AddShippingInfoEventArgs extends CheckoutEventArgs {
	shipping_tier?: string;
}

interface PurchaseEventArgs extends CheckoutEventArgs {
	transaction_id: string;
	shipping?: number;
	tax?: number;
}

interface GoogleAnalyticsSelectItemArgs {
	item_list_id?: string;
	item_list_name?: string;
	items: GoogleAnalyticsOrderItem[];
}

export default function setup() {
	addRocEventHandler('added-to-cart', ({ items, currency }) => {
		const googleAnalyticsItems = convertProductTrackingItemsToGoogleAnalyticsItems(items);
		const value = getValueForGoogleAnalyticsEvent(items);

		const args = {
			currency,
			value,
			items: googleAnalyticsItems,
		};

		gtag('event', 'add_to_cart', args);
	});

	addRocEventHandler('remove-from-cart', ({ items, currency }) => {
		const googleAnalyticsItems = convertProductTrackingItemsToGoogleAnalyticsItems(items);
		const value = getValueForGoogleAnalyticsEvent(items);

		const args = {
			currency,
			value,
			items: googleAnalyticsItems,
		};

		gtag('event', 'remove_from_cart', args);
	});

	addRocEventHandler('add-to-save-for-later', ({ items, currency }) => {
		const googleAnalyticsItems = convertProductTrackingItemsToGoogleAnalyticsItems(items);
		const value = getValueForGoogleAnalyticsEvent(items);

		const args = {
			currency,
			value,
			items: googleAnalyticsItems,
		};

		gtag('event', 'add_to_wishlist', args);
	});

	addRocEventHandler('product-viewed', ({ productTracking }) => {
		// "view_item" event should be triggered when a user views product or item details.
		const googleAnalyticsItem = convertProductTrackingDtoToGoogleAnalyticsItem({ productTracking, index: 0 });

		const args = {
			items: [googleAnalyticsItem],
			currency: productTracking.currency,
			value: productTracking.rawDefaultSalePrice || productTracking.rawDefaultBasePrice,
		};

		gtag('event', 'view_item', args);
	});

	addRocEventHandler('checkout-step-changed', ({ step }) => {
		switch (step) {
			case CheckoutStep.Loading:
			case CheckoutStep.LoginOrGuest:
			case CheckoutStep.ShippingAddress:
			case CheckoutStep.ShippingMethod:
			case CheckoutStep.ShippingReview:
			case CheckoutStep.Payment:
			case CheckoutStep.Review:
				break;
		}
	});

	addRocEventHandler('begin-checkout', ({ cart }) => {
		const items = convertOrderToGoogleAnalyticItems(cart);

		const loadingArgs: CheckoutEventArgs = {
			items,
			currency: cart.currency,
			value: cart.subtotal.basePrice.rawValue + cart.shippingTotal.basePrice.rawValue,
			coupon: cart.promotions.map((promo) => promo.couponCode).join(','),
		};

		gtag('event', 'begin_checkout', loadingArgs);
	});

	addRocEventHandler('add-shipping-info', ({ cart, shippingTier }) => {
		const items = convertOrderToGoogleAnalyticItems(cart);

		const shippingMethodArgs: AddShippingInfoEventArgs = {
			currency: cart.currency,
			value: cart.total.basePrice.rawValue,
			coupon: cart.promotions.map((promo) => promo.couponCode).join(','),
			shipping_tier: shippingTier,
			items,
		};

		gtag('event', 'add_shipping_info', shippingMethodArgs);
	});

	addRocEventHandler('add-payment-info', ({ cart }) => {
		const items = convertOrderToGoogleAnalyticItems(cart);

		const paymentInfoArgs: CheckoutEventArgs = {
			currency: cart.currency,
			value: cart.total.basePrice.rawValue,
			coupon: cart.promotions.map((promo) => promo.couponCode).join(','),
			items,
		};

		gtag('event', 'add_payment_info', paymentInfoArgs);
	});

	addRocEventHandler('order-placed', ({ order }) => {
		const args: PurchaseEventArgs = {
			transaction_id: order.id,
			value: order.total.basePrice.rawValue,
			tax: order.tax?.basePrice?.rawValue ?? 0,
			shipping: order.shippingTotal.basePrice.rawValue,
			coupon: order.promotions.map((promo) => promo.couponCode).join(','),
			currency: order.currency,
			items: convertOrderToGoogleAnalyticItems(order),
		};

		gtag('event', 'purchase', args);
	});

	interface ViewItemListData {
		item_list_id?: string | null;
		item_list_name?: string | null;
		items: GoogleAnalyticsOrderItem[];
	}

	addRocEventHandler('list-viewed', ({ listId, listName, items }) => {
		const eventData: ViewItemListData = {
			item_list_id: listId,
			item_list_name: listName,
			items: items.map((item, index) =>
				convertProductTrackingDtoToGoogleAnalyticsItem({ productTracking: item, index }),
			),
		};

		gtag('event', 'view_item_list', eventData);
	});

	addRocEventHandler('product-clicked', ({ listId, listName, productTracking }) => {
		const ga4Data = convertProductTrackingDtoToGoogleAnalyticsItem({ productTracking, index: 0 });
		const args: GoogleAnalyticsSelectItemArgs = {
			item_list_id: listId,
			item_list_name: listName,
			items: [ga4Data],
		};

		gtag('event', 'select_item', args);
	});

	addRocEventHandler('cart-page-viewed', ({ cart }) => {
		const items = convertOrderToGoogleAnalyticItems(cart);

		const args = {
			currency: cart.currency,
			value: cart.total.basePrice.rawValue,
			items,
		};

		gtag('event', 'view_cart', args);
	});

	addRocEventHandler('add-to-wishlist', ({ items, currency }) => {
		const googleAnalyticsItems = convertProductTrackingItemsToGoogleAnalyticsItems(items);
		const value = getValueForGoogleAnalyticsEvent(items);

		const args: GoogleAnalyticsBaseEventArgs = {
			currency,
			value,
			items: googleAnalyticsItems,
		};

		gtag('event', 'add_to_wishlist', args);
	});
}
