import setup from './google-analytics-enhanced-ecommerce-tracker';
import { setupViewListObservers } from './google-analytics-view-list-observer-setup';
import { setupProductClickedEvent } from 'features/tracking/trackers/commerce/roc-product-clicked-event-setup';

setup();
// Setup "view list" observers for SSR-page tracking
setupViewListObservers();

// Setup "product viewed" onClick events for SSR-page tracking
setupProductClickedEvent();
