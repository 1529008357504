import { ProductTrackingDto } from '~/features/commerce/product-details/types';
import { dispatchRocEvent } from '~/shared/hooks/useRocEventHandler';

interface ProductClickDataset {
	rocTrackingProductInfo?: string;
	rocTrackingListId?: string;
	rocTrackingListName?: string;
	rocProductClickEnabled?: boolean;
}

/**
 * Setup onclick handlers for product links in SSR pages to fire the ROC product-viewed event
 */
export function setupProductClickedEvent() {
	const anchorElements = document.querySelectorAll('[data-roc-product-click-enabled]');

	anchorElements.forEach((element) => {
		const dataset = (element as HTMLElement).dataset! as ProductClickDataset;

		const trackingInfoJson = dataset.rocTrackingProductInfo;
		const trackingInfo = JSON.parse(trackingInfoJson ?? '') as ProductTrackingDto;

		const url = (element as HTMLAnchorElement).href ?? '';

		(element as HTMLAnchorElement).addEventListener('click', () => {
			dispatchRocEvent('product-clicked', {
				listId: dataset.rocTrackingListId,
				listName: dataset.rocTrackingListName,
				productTracking: trackingInfo,
				url,
			});
		});
	});
}
